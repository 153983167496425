import { Center, Float } from '@react-three/drei'
import Lights from './components/Lights.jsx'
import OS from './components/OS.jsx'
import BackgroundText from './components/BackgroundText.jsx'
import { Suspense } from 'react'


export default function Experience()
{
    // colors for text and background
    const colors =
    {
        lightGray: '#DFDFDF',
        orange: '#FF5824',
        black: '#191919',
        midGray: '#827773',
        gray: '#CCC7C4'
    }

    return <>

        <color args={[colors.black]} attach='background' />

        <Lights />

        <BackgroundText
            textColors={ colors }
            position={[0, 0.7, 0 ]}
        />

        <Center>
            <Float speed={ 5 }>
                <Suspense fallback={null}>
                    <OS 
                        scale={0.04}
                    />
                </Suspense>
            </Float>
        </Center>

    </>
}