/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useEffect, useRef } from 'react'
import { useGLTF, MeshTransmissionMaterial, useFBO, useScroll } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'
import gsap  from 'gsap'
import useMouse from '../hooks/useMouse'
import { Euler, MathUtils, Vector3 } from 'three'

export default function OS(props) 
{

    const oRef = useRef() 
    const { nodes } = useGLTF('/models/OS.glb')
    const buffer = useFBO()
    const { x, y } = useMouse()
    const scrollDate = useScroll()
    const offsetMultiplier = 10



    useEffect( () => 
    {

      const groupWorldPosition = new Vector3()

      oRef.current.getWorldPosition( groupWorldPosition )

      oRef.current.children.forEach( ( mesh ) =>
    {

      mesh.originalPosition = mesh.position.clone()

      const meshWorldPosition = new Vector3()
      mesh.getWorldPosition( meshWorldPosition )

      mesh.directionVector = meshWorldPosition.clone().sub( groupWorldPosition ).normalize()
      mesh.targetPosition = mesh.originalPosition.clone().add( mesh.directionVector.clone().multiplyScalar( 3 ) )

      mesh.originalRotation = mesh.rotation.clone()
      mesh.TargetRotation = new Euler( Math.random * Math.PI, Math.random * Math.PI, Math.random * Math.PI )

    })

    }, [] )


  useFrame( ( state, delta ) =>
  {

    state.gl.setRenderTarget(buffer)
    state.gl.render(state.scene, state.camera)
    state.gl.setRenderTarget(null)

    oRef.current.children.forEach( ( mesh ) =>
    {
      if( scrollDate.offset < 0.000001 )
      {
        if( mesh.name === 'O')
        {
          mesh.visible = true
        }
        else
        {
          mesh.visible = false
        }
      }
      else
      {
        if( mesh.name === 'O' )
        {
          mesh.visible = false
        }
        else
        {
          mesh.visible = true
        }
      }

      mesh.position.x = MathUtils.lerp( mesh.originalPosition.x, mesh.targetPosition.x * offsetMultiplier, scrollDate.offset )
      mesh.position.y = MathUtils.lerp( mesh.originalPosition.y, mesh.targetPosition.y * offsetMultiplier, scrollDate.offset )
      mesh.position.z = MathUtils.lerp( mesh.originalPosition.z, mesh.targetPosition.z * offsetMultiplier, scrollDate.offset )
    } )


    gsap.to( oRef.current.rotation, {
        y: gsap.utils.mapRange( 0, window.innerWidth, -.3, .3, x ),
        x: gsap.utils.mapRange( 0, window.innerHeight, -.2, .2, y )
      })

  })

  return (
    <group {...props} dispose={null} ref={oRef}>
      <mesh
        name="O"
        geometry={nodes.O.geometry}
        rotation={[-Math.PI / 2, 0, 0]}
        >
        <MeshTransmissionMaterial 
            transmission={ 1 }
            thickness={ 5 }
            backside={ true }
            ior={ 2 }
            chromaticAberration={ 2 }
            roughness={ 0.09 }
            anisotropicBlur={ .8 }
            distortion={ 2 }
            buffer={buffer.texture}
        />
      </mesh>
      <mesh
        name="O_cell"
        geometry={nodes.O_cell.geometry}
        position={[-30.724, -19.317, -4.508]}
      >
        <MeshTransmissionMaterial 
            transmission={ 1 }
            thickness={ 5 }
            backside={ true }
            ior={ 2 }
            chromaticAberration={ 2 }
            roughness={ 0.09 }
            anisotropicBlur={ .8 }
            distortion={ 2 }
            buffer={buffer.texture}
        />
      </mesh>
      <mesh
        name="O_cell002"
        geometry={nodes.O_cell002.geometry}
        position={[-5.87, -23.265, 2.864]}
      >
          <MeshTransmissionMaterial 
            transmission={ 1 }
            thickness={ 5 }
            backside={ true }
            ior={ 2 }
            chromaticAberration={ 2 }
            roughness={ 0.09 }
            anisotropicBlur={ .8 }
            distortion={ 2 }
            buffer={buffer.texture}
        />
      </mesh>
      <mesh
        name="O_cell003"
        geometry={nodes.O_cell003.geometry}
        position={[10.446, -11.654, -5.069]}
        >
        <MeshTransmissionMaterial 
          transmission={ 1 }
          thickness={ 5 }
          backside={ true }
          ior={ 2 }
          chromaticAberration={ 2 }
          roughness={ 0.09 }
          anisotropicBlur={ .8 }
          distortion={ 2 }
          buffer={buffer.texture}
      />
    </mesh>
      <mesh
        name="O_cell005"
        geometry={nodes.O_cell005.geometry}
        position={[-9.03, -18.932, -1.528]}
        >
        <MeshTransmissionMaterial 
          transmission={ 1 }
          thickness={ 5 }
          backside={ true }
          ior={ 2 }
          chromaticAberration={ 2 }
          roughness={ 0.09 }
          anisotropicBlur={ .8 }
          distortion={ 2 }
          buffer={buffer.texture}
      />
    </mesh>
      <mesh
        name="O_cell006"
        geometry={nodes.O_cell006.geometry}
        position={[-6.042, 28.26, 0.135]}
        >
        <MeshTransmissionMaterial 
          transmission={ 1 }
          thickness={ 5 }
          backside={ true }
          ior={ 2 }
          chromaticAberration={ 2 }
          roughness={ 0.09 }
          anisotropicBlur={ .8 }
          distortion={ 2 }
          buffer={buffer.texture}
      />
    </mesh>
      <mesh
        name="O_cell007"
        geometry={nodes.O_cell007.geometry}
        position={[-28.617, -26.069, -4.1]}
        >
        <MeshTransmissionMaterial 
          transmission={ 1 }
          thickness={ 5 }
          backside={ true }
          ior={ 2 }
          chromaticAberration={ 2 }
          roughness={ 0.09 }
          anisotropicBlur={ .8 }
          distortion={ 2 }
          buffer={buffer.texture}
      />
    </mesh>
      <mesh
        name="O_cell008"
        geometry={nodes.O_cell008.geometry}
        position={[8.244, 10.241, 1.457]}
        >
        <MeshTransmissionMaterial 
          transmission={ 1 }
          thickness={ 5 }
          backside={ true }
          ior={ 2 }
          chromaticAberration={ 2 }
          roughness={ 0.09 }
          anisotropicBlur={ .8 }
          distortion={ 2 }
          buffer={buffer.texture}
      />
    </mesh>
      <mesh
        name="O_cell009"
        geometry={nodes.O_cell009.geometry}
        position={[-31.513, -13.621, -3.73]}
        >
        <MeshTransmissionMaterial 
          transmission={ 1 }
          thickness={ 5 }
          backside={ true }
          ior={ 2 }
          chromaticAberration={ 2 }
          roughness={ 0.09 }
          anisotropicBlur={ .8 }
          distortion={ 2 }
          buffer={buffer.texture}
      />
    </mesh>
      <mesh
        name="O_cell010"
        geometry={nodes.O_cell010.geometry}
        position={[-21.383, -29.939, 4.097]}
        >
        <MeshTransmissionMaterial 
          transmission={ 1 }
          thickness={ 5 }
          backside={ true }
          ior={ 2 }
          chromaticAberration={ 2 }
          roughness={ 0.09 }
          anisotropicBlur={ .8 }
          distortion={ 2 }
          buffer={buffer.texture}
      />
    </mesh>
      <mesh
        name="O_cell011"
        geometry={nodes.O_cell011.geometry}
        position={[-31.405, -21.892, -2.768]}
        >
        <MeshTransmissionMaterial 
          transmission={ 1 }
          thickness={ 5 }
          backside={ true }
          ior={ 2 }
          chromaticAberration={ 2 }
          roughness={ 0.09 }
          anisotropicBlur={ .8 }
          distortion={ 2 }
          buffer={buffer.texture}
      />
    </mesh>
      <mesh
        name="O_cell012"
        geometry={nodes.O_cell012.geometry}
        position={[11.672, 20.358, -0.756]}
        >
        <MeshTransmissionMaterial 
          transmission={ 1 }
          thickness={ 5 }
          backside={ true }
          ior={ 2 }
          chromaticAberration={ 2 }
          roughness={ 0.09 }
          anisotropicBlur={ .8 }
          distortion={ 2 }
          buffer={buffer.texture}
      />
    </mesh>
      <mesh
        name="O_cell013"
        geometry={nodes.O_cell013.geometry}
        position={[3.687, -18.522, -3.329]}
        >
        <MeshTransmissionMaterial 
          transmission={ 1 }
          thickness={ 5 }
          backside={ true }
          ior={ 2 }
          chromaticAberration={ 2 }
          roughness={ 0.09 }
          anisotropicBlur={ .8 }
          distortion={ 2 }
          buffer={buffer.texture}
      />
    </mesh>
      <mesh
        name="O_cell014"
        geometry={nodes.O_cell014.geometry}
        position={[-11.384, -23.507, -5.188]}
        >
        <MeshTransmissionMaterial 
          transmission={ 1 }
          thickness={ 5 }
          backside={ true }
          ior={ 2 }
          chromaticAberration={ 2 }
          roughness={ 0.09 }
          anisotropicBlur={ .8 }
          distortion={ 2 }
          buffer={buffer.texture}
      />
    </mesh>
      <mesh
        name="O_cell015"
        geometry={nodes.O_cell015.geometry}
        position={[-30.264, 2.142, -0.133]}
        >
        <MeshTransmissionMaterial 
          transmission={ 1 }
          thickness={ 5 }
          backside={ true }
          ior={ 2 }
          chromaticAberration={ 2 }
          roughness={ 0.09 }
          anisotropicBlur={ .8 }
          distortion={ 2 }
          buffer={buffer.texture}
      />
    </mesh>
      <mesh
        name="O_cell016"
        geometry={nodes.O_cell016.geometry}
        position={[-25.958, -28.593, 3.103]}
        >
        <MeshTransmissionMaterial 
          transmission={ 1 }
          thickness={ 5 }
          backside={ true }
          ior={ 2 }
          chromaticAberration={ 2 }
          roughness={ 0.09 }
          anisotropicBlur={ .8 }
          distortion={ 2 }
          buffer={buffer.texture}
      />
    </mesh>
      <mesh
        name="O_cell017"
        geometry={nodes.O_cell017.geometry}
        position={[8.055, -12.264, 0.854]}
        >
        <MeshTransmissionMaterial 
          transmission={ 1 }
          thickness={ 5 }
          backside={ true }
          ior={ 2 }
          chromaticAberration={ 2 }
          roughness={ 0.09 }
          anisotropicBlur={ .8 }
          distortion={ 2 }
          buffer={buffer.texture}
      />
    </mesh>
      <mesh
        name="O_cell018"
        geometry={nodes.O_cell018.geometry}
        position={[-22.817, -29.656, -4.181]}
        >
        <MeshTransmissionMaterial 
          transmission={ 1 }
          thickness={ 5 }
          backside={ true }
          ior={ 2 }
          chromaticAberration={ 2 }
          roughness={ 0.09 }
          anisotropicBlur={ .8 }
          distortion={ 2 }
          buffer={buffer.texture}
      />
    </mesh>
      <mesh
        name="O_cell019"
        geometry={nodes.O_cell019.geometry}
        position={[-10.676, -29.277, -2.01]}
        >
        <MeshTransmissionMaterial 
          transmission={ 1 }
          thickness={ 5 }
          backside={ true }
          ior={ 2 }
          chromaticAberration={ 2 }
          roughness={ 0.09 }
          anisotropicBlur={ .8 }
          distortion={ 2 }
          buffer={buffer.texture}
      />
    </mesh>
      <mesh
        name="O_cell020"
        geometry={nodes.O_cell020.geometry}
        position={[-18.413, -18.797, -0.719]}
        >
        <MeshTransmissionMaterial 
          transmission={ 1 }
          thickness={ 5 }
          backside={ true }
          ior={ 2 }
          chromaticAberration={ 2 }
          roughness={ 0.09 }
          anisotropicBlur={ .8 }
          distortion={ 2 }
          buffer={buffer.texture}
      />
    </mesh>
      <mesh
        name="O_cell021"
        geometry={nodes.O_cell021.geometry}
        position={[-30.269, -17.665, 4.207]}
        >
        <MeshTransmissionMaterial 
          transmission={ 1 }
          thickness={ 5 }
          backside={ true }
          ior={ 2 }
          chromaticAberration={ 2 }
          roughness={ 0.09 }
          anisotropicBlur={ .8 }
          distortion={ 2 }
          buffer={buffer.texture}
      />
    </mesh>
      <mesh
        name="O_cell024"
        geometry={nodes.O_cell024.geometry}
        position={[-31.112, -5.549, -3.983]}
        >
        <MeshTransmissionMaterial 
          transmission={ 1 }
          thickness={ 5 }
          backside={ true }
          ior={ 2 }
          chromaticAberration={ 2 }
          roughness={ 0.09 }
          anisotropicBlur={ .8 }
          distortion={ 2 }
          buffer={buffer.texture}
      />
    </mesh>
      <mesh
        name="O_cell025"
        geometry={nodes.O_cell025.geometry}
        position={[-30.101, -23.75, 4.032]}
        >
        <MeshTransmissionMaterial 
          transmission={ 1 }
          thickness={ 5 }
          backside={ true }
          ior={ 2 }
          chromaticAberration={ 2 }
          roughness={ 0.09 }
          anisotropicBlur={ .8 }
          distortion={ 2 }
          buffer={buffer.texture}
      />
    </mesh>
      <mesh
        name="O_cell026"
        geometry={nodes.O_cell026.geometry}
        position={[-29.713, -22.008, -4.884]}
        >
        <MeshTransmissionMaterial 
          transmission={ 1 }
          thickness={ 5 }
          backside={ true }
          ior={ 2 }
          chromaticAberration={ 2 }
          roughness={ 0.09 }
          anisotropicBlur={ .8 }
          distortion={ 2 }
          buffer={buffer.texture}
      />
    </mesh>
      <mesh
        name="O_cell001"
        geometry={nodes.O_cell001.geometry}
        position={[-21.108, -7.656, -2.905]}
        >
        <MeshTransmissionMaterial 
          transmission={ 1 }
          thickness={ 5 }
          backside={ true }
          ior={ 2 }
          chromaticAberration={ 2 }
          roughness={ 0.09 }
          anisotropicBlur={ .8 }
          distortion={ 2 }
          buffer={buffer.texture}
      />
    </mesh>
      <mesh
        name="O_cell004"
        geometry={nodes.O_cell004.geometry}
        position={[5.977, -13.196, -3.774]}
        >
        <MeshTransmissionMaterial 
          transmission={ 1 }
          thickness={ 5 }
          backside={ true }
          ior={ 2 }
          chromaticAberration={ 2 }
          roughness={ 0.09 }
          anisotropicBlur={ .8 }
          distortion={ 2 }
          buffer={buffer.texture}
      />
    </mesh>
    </group>
  )
}

useGLTF.preload('/models/OS.glb')