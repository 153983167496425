import { Text } from "@react-three/drei"
import { useEffect, useRef } from "react"
import gsap from 'gsap'

const tl = gsap.timeline({ delay: 1 })



export default function BackgroundText( props )
{
    const otanoRef = useRef()
    const designRef = useRef()

    const { textColors } = props

    useEffect( () =>
    {
        // otanoRef.current.material.opacity = 0
        // designRef.current.material.opacity = 0

        // tl.fromTo( otanoRef.current.position, { x: 0, duration: 1.5 }, { x: -0.6 } )
        // tl.to( otanoRef.current.material, { opacity: 1 }, '<' )
        // tl.fromTo( designRef.current.position, { x: 0, duration: 1.5 }, { x: 0.6 } )


    }, [])
    
    return (

        <group { ...props }>
            <Text
                    position={[-0.6,0, -1.2]}
                    ref={ otanoRef }
                    font='/fonts/otano-sans.woff'
                    characters="Ootaño"
                    fontSize={ 1.7 }
                    color={textColors.lightGray}
                >
                    Otaño
                </Text>
                <Text
                    ref={ designRef }
                    position={[0.6, -1.3, -1.2]}
                    font='/fonts/BNBergenSt.woff'
                    characters="Studios"
                    fontSize={ 1.35 }
                    color={textColors.black}
                    strokeWidth='1%'
                    strokeColor={ textColors.lightGray }
                >
                    Studio
                </Text>
            </group>
    )
}