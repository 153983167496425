import './style.css'
import ReactDOM from 'react-dom/client'
import { Canvas } from '@react-three/fiber'
import Experience from './Experience.jsx'
import { Environment, ScrollControls } from '@react-three/drei'


const root = ReactDOM.createRoot(document.querySelector('#root'))

root.render(
    <Canvas
        camera={ {
            fov: 45,
            near: 0.1,
            far: 200,
            position: [ 0, 0, 6 ]
        } }
        dpr={[1, 2]}
    >
        <ScrollControls pages={1}>
            <Experience />
        </ScrollControls>

        <Environment files='./images/hdr/gradient_min_27.hdr' />

    </Canvas>
)